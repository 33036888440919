<template>
    <section class="why-choose-wrap style1 pb-100 bg-bunting pt-5" v-if="$store.state.whyUs != null">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-5">
                    <div class="wh-img-wrap">
                        <img :src="$store.state.url + $store.state.company.hero_image" alt="Image">
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="wh-content">
                        <div class="content-title style1">
                            <span>{{ $store.state.whyUs.small_title }}</span>
                            <h3>{{ $store.state.whyUs.title }}</h3>
                            <p>{{ $store.state.whyUs.description }}</p>
                        </div>
                        <div class="feature-item-wrap">
                            <div class="feature-item" v-for="item in this.$store.state.whyUs.why_us" :key="item.id">
                                <span class="feature-icon">
                                    <i :class="item.icon_name"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>{{item.title}}</h3>
                                    <p>{{item.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WhyChooseUs',
}
</script>