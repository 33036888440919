<template>
    <div class="account-wrap pb-5 pt-5" v-if="$store.state.trainings != null">
        <div class="container">
            <div class="section-title style1 text-center mb-40">
<!--                <span>Open Account</span>-->
                <h2>Training Registrations</h2>
            </div>
            <form v-on:submit.prevent="submitForm" class="account-form">
                <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="fname">Full name</label>
                        <input type="text" id="fname" name="fname" v-model="formData.fullName" required>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="phone">Mobile Number</label>
                        <input type="number" id="phone" name="phone" v-model="formData.phone" required>
                      </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="email">Email Address</label>
                            <input type="email" id="email" name="email" v-model="formData.email" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="dob">Address</label>
                        <input type="text" id="address" name="address" v-model="formData.address" required>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="gender">Gender</label>
                        <select name="gender" id="gender" v-model="formData.gender" required>
                          <option value="1">Male </option>
                          <option value="2">Female </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="dob">Payment Receipt</label>
                        <input type="file" id="receipt" name="receipt"  accept="image/*" v-on:change="handleFileChange">
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <h4>Choose your training</h4>
                    </div>
                    <div class="row justify-content-center">
                      <div v-for="(training, index) in getTrainings()" class="col-xl-4 col-lg-6 col-md-6" @click="checkCart(index)">
                        <div class="service-card style1" v-if="tutorial === index" style="background-color: #e7e7e7">

                          <span class="service-icon">
                            <img
                                :src="$store.state.url + training.logo"
                                alt="Image"
                            />
                          </span>
                          <p style="font-size: 20px; font-weight: bold">
                            {{ training.title }} <span style="font-size: 12px"> ({{ training.price_value }})</span>
                          </p>
                          <p v-html="training.short_description"></p>
                        </div>
                        <div class="service-card style1" v-else>
                          <span class="service-icon">
                            <img
                                :src="$store.state.url + training.logo"
                                alt="Image"
                            />
                          </span>
                          <p style="font-size: 20px; font-weight: bold">
                            {{ training.title }} <span style="font-size: 12px"> ({{ training.price_value }})</span>
                          </p>
                          <p v-html="training.short_description"></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <button class="btn style1 w-100 d-block" type="submit">Register Now ({{total}} DA) <b-spinner v-if="isLoading" class="mx-2" variant="white" label="Spinning" small></b-spinner></button>

                    </div>
                  <div class="mt-3">
                    <b-alert show dismissible v-if="showSuccess">
                      Registration success, we will contact you soon!
                    </b-alert>
                    <b-alert show dismissible v-if="showError" variant="warning">
                      Registration failed, please try again!
                    </b-alert>
                  </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from '../../axios';
export default {
  name: 'Account',
  methods: {
    checkCart(index) {
      if(index === this.tutorial) {
        this.tutorial = -1
        this.total = 0
      }else{
        this.tutorial = index
        this.total = this.trainings[index].numeric_price
      }
    },
    getTrainings() {
      this.trainings = this.$store.state.trainings
      return this.trainings
    },
    submitForm: function () {
      // Create form data object to send files
      this.showSuccess = false;
      this.showError = false;
      if(this.total > 0) {
        this.isLoading = true;
        const formData = new FormData();
        formData.append('full_name', this.formData.fullName);
        formData.append('email', this.formData.email);
        formData.append('address', this.formData.address);
        formData.append('phone', this.formData.phone);
        formData.append('gender', this.formData.gender);
        formData.append('payment_receipt', this.formData.paymentReceipt);
        formData.append('training_id', this.trainings[this.tutorial].id);

        // Make an Axios POST request
        axios.post('register', formData)
            .then(response => {
              this.isLoading = false;
              console.log('Server response:', response.data);
              this.formData.fullName = '';
              this.formData.email = '';
              this.formData.address = '';
              this.formData.phone = '';
              this.formData.gender = '0';
              this.formData.paymentReceipt = null;
              this.tutorial = -1;
              this.total = 0;
              this.showSuccess = true;
              this.showError = false;
            })
            .catch(error => {
              this.isLoading = true;
              this.showError = true;
              this.showSuccess = false;
              console.error('Error sending data to server:', error);
            });
      }
    },
    handleFileChange: function (event) {
      this.formData.paymentReceipt = event.target.files[0];
    }
  },
  data: () => ({
    total: 0.00,
    tutorial: -1,
    trainings: [],
    formData: {
      fullName: '',
      email: '',
      address: '',
      phone: '',
      gender: '0',
      paymentReceipt: null  // This will hold the file data
    },
    isLoading: false,
    showSuccess: false,
    showError: false,
  }),
}
</script>