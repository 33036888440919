<template>
    <div class="service-wrap pb-5 pt-5" v-if="$store.state.trainings != null">
        <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-4 col-lg-6 col-md-6" v-for="item in this.$store.state.trainings" :key="item.id">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i><img :src="$store.state.url + item.logo"></i></span>
                                <h3><router-link to="/service-details" @click="setCurrentTraining(item.id)">{{item.title}}</router-link></h3>
                            </div>
                            <p>{{item.short_description}}</p>
                            <router-link to="/service-details" @click="setCurrentTraining(item.id)" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>
                        </div>
                    </div>
              </div>
            </div>
<!--            <ul class="page-nav list-style mt-20">-->
<!--                <li><a href="/services-two"><i class="flaticon-back"></i></a></li>-->
<!--                <li><a class="active" href="/services-two">1</a></li>-->
<!--                <li><a href="/services-two">2</a></li>-->
<!--                <li><a href="/services-two">3</a></li>-->
<!--                <li><a href="/services-two"><i class="flaticon-next-1"></i></a></li>-->
<!--            </ul>-->
        </div>
    </div>
</template>

<script>
export default {
  name: 'Services',
  methods: {
      setCurrentTraining(id) {
        localStorage.setItem('currentTraining', id)
        this.$store.commit('setCurrentTraining', id);
      }
  }
}
</script>