<template>
  <div class="service-details-wrap pb-100 pt-5" v-if="$store.state.trainings != null && getCurrentTraining() !== 0">
    <div class="container">
      <div class="row gx-5">
        <div class="col-xl-8 col-lg-12">
          <div class="service-desc">
            <lightgallery
              :settings="{ speed: 500, plugins: plugins }"
              :onInit="onInit"
              :onBeforeSlide="onBeforeSlide"
            >
              <a
                v-for="item in items"
                :key="item.id"
                :data-lg-size="item.size"
                :data-src="$store.state.url + currentTraining.hero_image"
              >
                <div class="service-img mb-25 d-block">
                  <img className="img-responsive" :src="$store.state.url + currentTraining.hero_image" />
                </div>
              </a>
            </lightgallery>
            <h2>{{ currentTraining.title }}</h2>
            <p>{{ currentTraining.description }}</p>
            <p>{{ currentTraining.aims_description }}</p>
            <ul class="content-feature-list style2 list-style">
              <li v-for="item in currentTraining.aims.split(';')" :key="item">
                <i class="ri-check-line"></i>
                {{ item.toString().trim() }}
              </li>
            </ul>
            <p>{{ currentTraining.requirements_description }}</p>
            <ul class="content-feature-list style2 list-style">
              <li v-for="item in currentTraining.requirements.split(';')" :key="item">
                <i class="ri-check-line"></i>
                {{ item.toString().trim() }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-lg-12">
          <div class="sidebar">
            <div class="sidebar-widget style2">
              <h4>{{ currentTraining.training_titles_description }}</h4>
              <div class="category-box style2">
                <ul class="list-style">
                  <li v-for="item in currentTraining.training_titles.split(';')" :key="item">
                    <router-link to="/service-details">
                      {{item.toString().trim()}}
                      <span><i class="flaticon-next"></i></span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="sidebar-widget style2">
              <h4>{{ currentTraining.hours_price_title }}</h4>
              <div class="schedule-widget">
                <ul class="list-style">
                  <li>
                    <h6>{{ currentTraining.duration_key }}</h6>
                    <p>{{ currentTraining.duration_value }}</p>
                  </li>
                  <li>
                    <h6>{{ currentTraining.day_key }}</h6>
                    <p>{{ currentTraining.day_value }}</p>
                  </li>
                  <li>
                    <h6>{{ currentTraining.price_key }}</h6>
                    <p>{{ currentTraining.price_value }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Lightgallery from "lightgallery/vue";
import lgZoom from "lightgallery/plugins/zoom";
let lightGallery = null;

export default {
  name: "ServicesDetails",
  components: {
    Lightgallery,
  },
  watch: {
    items() {
      this.$nextTick(() => {
        lightGallery.refresh();
      });
    },
  },
  data: () => ({
    plugins: [lgZoom],
    items: [
      {
        id: "1",
        src: require("../../assets/images/service/single-service-1.png"),
        thumb: require("../../assets/images/service/single-service-1.png"),
      },
    ],
    currentTraining: {}
  }),
  methods: {
    onInit: (detail) => {
      lightGallery = detail.instance;
    },
    onBeforeSlide: () => {
      console.log("calling before slide");
    },
    getCurrentTraining() {
      var trainingId = this.$store.state.currentTraining;
      if(trainingId !== 0) {
        this.currentTraining = this.$store.state.trainings.filter((element) => element.id === trainingId)[0]
        return trainingId
      }
      return 0
    }
  },
  mounted() {
    this.getCurrentTraining();
  }
};
</script>
<style lang="css">
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lightgallery.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lg-zoom.css");
</style>
