<template>
  <div class="service-wrap style3 ptb-100 bg-rock" v-if="$store.state.services != null">
    <div class="container">
      <div class="section-title style1 text-center mb-40">
        <span>{{$store.state.services.small_title}}</span>
        <h3 class="text-white">{{$store.state.services.title}}</h3>
      </div>
      <div class="row gx-5 align-items-center">
        <div class="col-md-6" v-for="item in this.$store.state.services.services" :key="item.id">
          <div class="service-card style3" style="background-color: #4e4d4d">
            <span class="service-icon">
              <i :class="item.icon_name"></i>
            </span>
            <div class="service-info">
              <h3>
                <router-link to="">{{ item.title }}</router-link>
              </h3>
              <p>
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>
